import BookingRawSlot from '@/calendesk/models/BookingRawSlot'

export default class BookingTimeSlot implements BookingRawSlot {
  public date: string;
  public time: string;
  public max: number;
  public used: number;
  public booked = false;

  // Employees that can still handle the booking
  availableEmployeeIds: number[];

  // Max bookings each employee can make
  employeeMaxBookings: Record<string | number, number>;

  public constructor (
    date: string,
    time: string,
    max: number,
    used: number,
    booked = false,
    availableEmployeeIds: number[] = [],
    employeeMaxBookings: Record<string | number, number> = {}) {
    this.date = date
    this.time = time
    this.max = max
    this.used = used
    this.booked = booked
    this.availableEmployeeIds = availableEmployeeIds
    this.employeeMaxBookings = employeeMaxBookings
  }
}
