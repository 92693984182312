import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import parseTime from '@/calendesk/tools/parseTime'
import BookingRawSlot from '@/calendesk/models/BookingRawSlot'

export default function (slots: BookingRawSlot[], serviceMaxPeople: number) {
  const result: Array<BookingTimeSlot> = []

  slots.forEach((spot: BookingRawSlot) => {
    const found = result.find((item: BookingTimeSlot) => item.time === spot.time)

    if (found) {
      found.used += spot.used
      found.max += serviceMaxPeople

      if (!spot.booked) {
        found.booked = false

        if (!found.availableEmployeeIds) {
          found.availableEmployeeIds = []
        }

        if (spot.availableEmployeeIds && spot.availableEmployeeIds.length > 0) {
          found.availableEmployeeIds.push(...spot.availableEmployeeIds.filter(id => !found.availableEmployeeIds.includes(id)))
          spot.availableEmployeeIds.forEach(id => {
            if (spot.employeeMaxBookings && spot.employeeMaxBookings[id]) {
              found.employeeMaxBookings[id] = spot.employeeMaxBookings[id]
            } else {
              found.employeeMaxBookings[id] = (serviceMaxPeople - spot.used)
            }
          })
        }
      }
    } else {
      const employeeMaxBookings: Record<string | number, number> = {}

      if (!spot.booked && spot.availableEmployeeIds && spot.availableEmployeeIds.length > 0) {
        spot.availableEmployeeIds.forEach(id => {
          employeeMaxBookings[id] = (serviceMaxPeople - spot.used)
        })
      }

      const usedSlots = spot.booked ? serviceMaxPeople : spot.used

      result.push(
        new BookingTimeSlot(
          spot.date,
          spot.time,
          serviceMaxPeople,
          usedSlots,
          spot.booked,
          spot.booked ? [] : (spot.availableEmployeeIds || []),
          employeeMaxBookings
        )
      )
    }
  })

  return result.sort((a, b) => parseTime(a.time) - parseTime(b.time))
}
