var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"hour-input",class:( _obj = {
    'hour-input-no-actions': _vm.loading
  }, _obj[_vm.roundClass] = true, _obj ),attrs:{"outlined":_vm.outlined,"color":_vm.color,"flat":_vm.flat}},[(_vm.loading)?_c('div',{staticClass:"text-center pa-2 mt-2"},_vm._l((10),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"mb-2",attrs:{"type":"image","width":"100%","height":"56"}})}),1):_vm._e(),(_vm.hours && _vm.hours.length > 0 && !_vm.loading)?_c('v-list',{attrs:{"color":_vm.color,"flat":_vm.flat}},[_c('v-list-item-group',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.hours),function(item,i){
  var _obj;
return _c('v-sheet',{key:i,staticClass:"ma-2",class:( _obj = {}, _obj[_vm.roundClass] = true, _obj['text-disabled'] =  _vm.showBookedSlots && item.booked, _obj['hour-wrapper'] =  !item.booked, _obj ),attrs:{"outlined":"","disabled":_vm.showBookedSlots && item.booked}},[_c('v-list-item',{staticClass:"text-center",attrs:{"value":item,"color":"primary","disabled":_vm.showBookedSlots && item.booked}},[_c('v-list-item-content',[_c('v-list-item-title',{class:{
              'text-decoration-line-through text-disabled': _vm.showBookedSlots && item.booked
            }},[_vm._v(" "+_vm._s(_vm._f("timeNotation")(item.time))+" ")]),(_vm.showGroupInfo)?_c('v-list-item-subtitle',[_c('small',[_vm._v(_vm._s(_vm.$trans('free_spots'))+": "+_vm._s(item.max - item.used))])]):_vm._e()],1),(_vm.bookingButton)?_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeHour && item.time === _vm.activeHour.time),expression:"activeHour && item.time === activeHour.time"}]},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('book', _vm.activeHour)}}},[_vm._v(" "+_vm._s(_vm.bookNowLabel)+" ")])],1):_vm._e(),(!_vm.bookingButton)?_c('v-list-item-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeHour && item.time === _vm.activeHour.time),expression:"activeHour && item.time === activeHour.time"}]},[_c('v-icon',{domProps:{"textContent":_vm._s('$check')}})],1):_vm._e()],1)],1)}),1)],1):(!_vm.loading)?_c('div',{staticClass:"hour-input__no-data"},[_c('v-container',{staticClass:"color-1-base"},[_c('v-row',[_c('v-col',{staticClass:"text-center font-weight-medium"},[(_vm.showLocationError)?_c('span',[_vm._v(_vm._s(_vm.$trans('select_location_calendar_warning')))]):_c('span',[_vm._v(_vm._s(_vm.$trans('no_free_hours')))])])],1),(_vm.firstAvailableDate)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$trans('first_available_date'))),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm._f("moment")(_vm.firstAvailableDate,'dddd D MMMM')))])])],1):_vm._e(),(_vm.firstAvailableDate)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$emit('date', _vm.firstAvailableDate)}}},[_vm._v(" "+_vm._s(_vm.$trans('select_date'))+" ")])],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }