





























import timeZones from '@/calendesk/lib/timeZones.ts'
import { mapActions, mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'

export default mixins(DraftElement).extend({
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      timeZones: [] as any[],
      timeZone: null as string | null,
      switchTimeNotation: null as string | null
    }
  },
  computed: {
    ...mapGetters({
      timeNotation: 'setup/getTimeNotation'
    })
  },
  watch: {
    value (val) {
      this.timeZone = val
    },
    timeZone (val) {
      this.$emit('input', val)
    },
    timeNotation () {
      this.switchTimeNotation = this.timeNotation
    }
  },
  created () {
    this.timeZone = this.value
    this.switchTimeNotation = this.timeNotation
    this.reloadTimeZones()
  },
  methods: {
    ...mapActions({
      setTimeNotation: 'setup/setTimeNotation'
    }),
    reloadTimeZones () {
      this.timeZones = timeZones.map((timeZone: string) => {
        return {
          text: timeZone
        }
      })
    },
    getText (timeZone: any): string {
      const momentTz = this.$moment().tz(timeZone.text)

      if (momentTz) {
        let hour = momentTz.format('HH:mm')
        hour = (this.$options.filters as any).timeNotation(hour)

        return `${timeZone.text} (${hour})`
      }

      return `${timeZone.text}`
    },
    getValue (timeZone: any): string {
      return timeZone.text
    },
    timeNotationOnChange (timeNotation: string) {
      this.setTimeNotation(timeNotation)
      this.reloadTimeZones()
    }
  }
})

